<div class="dashboard-body">

    <mat-card class="mat-cards">
        <mat-card-header>
            <mat-card-title>Session Token</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            {{ token }}
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-cards">
        <mat-card-header>
            <mat-card-title>Endpoint Arn</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            {{ endpointARN }}
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-cards">
        <mat-card-header>
            <mat-card-title>Subscription Arn</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            {{ subscriptionARN }}
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-cards">
        <mat-card-header>
            <mat-card-title>Messages</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            {{ message | async | json }}
        </mat-card-content>
    </mat-card>




</div>