import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  async requestPermission() {
    console.log("Requesting Message Token");

    return new Promise((resolve, reject) => {
      this.angularFireMessaging.requestToken.subscribe(token => {
        console.log(token);
        console.log("Message Token Obtained");
        resolve(token);
      }, error => {
        reject(error);
      })

    })


  }

  receiveMessage() {
    console.log("Receive Message Fn registered");
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}

