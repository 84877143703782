import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SnsService {
  SNS: AWS.SNS;



  constructor() { 
    this.SNS = new AWS.SNS({region : "ap-south-1", accessKeyId: environment.AWS_ACCESS_KEY_ID, secretAccessKey: environment.AWS_SECRET_ACCESS_KEY});
  }

  async createEndpoint(token, userData) {

    var params = {
      PlatformApplicationArn: 'arn:aws:sns:ap-south-1:269089152134:app/GCM/WebPushNotification', 
      Token: token, 
      Attributes: {
        UserId: userData.Username
      },
      CustomUserData: JSON.stringify(userData)
    };
    
    try {
      const data = this.SNS.createPlatformEndpoint(params).promise();
      return data;
    } catch (error) {
      console.error(error);
    }

  }

  async getEndpointAttributes(arn) {
    var params = {
      EndpointArn: arn
    };

    try {
      const data = this.SNS.getEndpointAttributes(params).promise();
      return data;
    } catch (error) {
      console.error(error);
    }

  }

  async subscribeTopic(endpointArn) {
    var params = {
      Protocol: 'application', 
      TopicArn: 'arn:aws:sns:ap-south-1:269089152134:BS-WebPushTopic',
      Attributes: {},
      Endpoint: endpointArn,
      ReturnSubscriptionArn: true || false
    };

    try {
      const data = this.SNS.subscribe(params).promise();
      return data;
    } catch (error) {
      console.error(error);
    }

  }
}
