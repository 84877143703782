import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../service/messaging.service';
import { SnsService } from '../service/sns.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  message: any;
  endpointARN: any;
  subscriptionARN: any;
  token: any = "Obtaining Device Token";

  constructor(private messagingService: MessagingService, private sns: SnsService) { }

  ngOnInit(): void {

    this.obtainToken();
  }


  async obtainToken() {
    this.token = await this.messagingService.requestPermission();
    this.subscribeTokenToSNS();
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

  async subscribeTokenToSNS() {
    try {
      const userData = {Username : "bnyth", Application : "Chrome"}
      const result = await this.sns.createEndpoint(this.token, userData);
      console.log(result.EndpointArn);
      this.endpointARN = result.EndpointArn;

      const subscribeResult = await this.sns.subscribeTopic(this.endpointARN);
      console.log(subscribeResult.SubscriptionArn);
      this.subscriptionARN = subscribeResult.SubscriptionArn

    } catch (error) {
      console.error(error);
    }
  }

}
