// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAqNtao5fFVcgCHx21w-7haRxspDGN0KK0",
    authDomain: "bs-webpushnotifications.firebaseapp.com",
    projectId: "bs-webpushnotifications",
    storageBucket: "bs-webpushnotifications.appspot.com",
    messagingSenderId: "297759310855",
    appId: "1:297759310855:web:da1db7e418d6274167d6a2",
    measurementId: "G-70XVFLJPH7"
  },
  AWS_ACCESS_KEY_ID: 'AKIAT5JXWTSDHQ2J2BNL',
  AWS_SECRET_ACCESS_KEY: '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'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
